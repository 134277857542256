import React from "react";

const PageNotFound = () => (
  <div>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn't exist... the sadness.</p>
  </div>
);

export default PageNotFound;
